import React from "react"
import Layout from "../components/privacyLayout"
import SEO from "../components/seo"
import {StaticQuery, graphql} from 'gatsby'
import PrivacyNotice from "../components/privacyNotice"


const Privacy = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          image: file(relativePath: { eq: "arrow.png" }) {
            childImageSharp {
              fluid(quality: 50, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Privacy" />
          

          <PrivacyNotice/>   


        </Layout>
      )}
    />
  )
}

export default Privacy
